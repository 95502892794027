:root {
  --lw: 45rem;
  --lh: 37.51531rem;

  --mw: 15rem;
  --mh: 12rem;

  --sw: 16rem;
  --sh: 13.75rem;
}

.animationContainer {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  transform: scale(0.6);
  @media screen and (max-width: 800px) {
    transform: scale(0.3);
    height: 50%;
  }
}

.animatedImage {
  position: absolute;
  offset-rotate: 0turn;
  transform-origin: center;
}

.animationContainer img:nth-child(1) {
  offset-path: path(
    "M3 306.422C45.5 49.9225 286.003 -16.0795 438.502 7.42176C614.5 20.9219 666.175 133.422 600.998 249.922C553.148 335.45 345.928 459.32 3 307.5"
  );
  /* "M3 230.565C34.8748 38.1917 215.251 -11.3095 329.624 6.3163C461.622 16.4413 500.378 100.816 451.496 188.19C415.609 252.336 260.195 345.238 3 231.374" */
  width: var(--lw);
  height: var(--lh);
  animation: animation1 10s linear infinite;
}

.animationContainer img:nth-child(2) {
  offset-path: path(
    "M442 7.92315C289.501 -15.5781 45.5 49.922 3 306.421C355 468.421 552.478 336.648 600.998 249.921C665.429 134.756 615.668 23.4989 444.5 7.92396"
  );
  /* offset-path: path(
    "M332.248 6.3984C217.875 -11.2274 34.8748 37.8974 3 230.271C266.999 351.77 415.106 252.941 451.496 187.896C499.819 101.522 462.499 18.0802 334.123 6.399"
  ); */
  width: var(--mw);
  height: var(--mh);
  animation: animation2 10s linear infinite;
  transform: scale(1.2);
}

@keyframes animation1 {
  0%,
  10% {
    offset-distance: 0%;
    width: var(--lw); /* 45rem */
    height: var(--lh); /* 37.5 */
    z-index: 1;
    transform: translateY(0) scale(1.4);
  }

  45% {
    width: var(--mw);
    height: var(--mh);
    offset-distance: 50%;
    z-index: 0;
    transform: translateY(-50px) scale(1.2);
  }
  55% {
    offset-distance: 50%;
    width: var(--mw);
    height: var(--mh);
    z-index: 0;
    transform: translateY(-50px) scale(1.2);
  }

  90% {
    offset-distance: 100%;
    width: var(--lw);
    height: var(--lh);
    z-index: 1;
    transform: translateY(0) scale(1.4);
  }
  100% {
    offset-distance: 100%;
    width: var(--lw);
    height: var(--lh);
    z-index: 1;
    transform: translateY(0) scale(1.4);
  }
}

@keyframes animation2 {
  0%,
  10% {
    offset-distance: 100%;
    width: var(--mw);
    height: var(--mh);
    z-index: 0;
    transform: translateY(-50px) scale(1.2);
  }

  45% {
    transform: scale(1.2);
    width: var(--lw);
    height: var(--lh);
    offset-distance: 37%;
    z-index: 1;
    transform: translateY(0) scale(1.2);
  }
  55% {
    transform: scale(1.2);
    offset-distance: 37%;
    width: var(--lw);
    height: var(--lh);
    z-index: 1;
    transform: translateY(0) scale(1.2);
  }

  90% {
    offset-distance: 0%;
    width: var(--mw);
    height: var(--mh);
    z-index: 0;
    transform: translateY(-50px) scale(1.2);
  }

  100% {
    offset-distance: 0%;
    width: var(--mw);
    height: var(--mh);
    z-index: 0;
    transform: translateY(-50px) scale(1.2);
  }
}

@keyframes animation3 {
  0%,
  10% {
    offset-distance: 0%;
    width: var(--sw);
    height: var(--sh);
    z-index: 1;
    transform: translateY(0) scale(1);
  }

  25% {
    width: var(--lw);
    height: var(--lh);
    offset-distance: 41%;
    z-index: 1;
  }
  35% {
    offset-distance: 41%;
    width: var(--lw);
    height: var(--lh);
    z-index: 1;
    transform: translateY(0) scale(1);
  }

  55% {
    width: var(--mw);
    height: var(--mh);
    offset-distance: 80%;
    z-index: 0;
    transform: translateY(-50px) scale(1.2);
  }
  65% {
    offset-distance: 80%;
    width: var(--mw);
    height: var(--mh);
    z-index: 0;
    transform: translateY(-50px) scale(1.2);
  }

  90% {
    offset-distance: 100%;
    width: var(--sw);
    height: var(--sh);
    z-index: 0;
    transform: translateY(0) scale(1);
  }

  100% {
    offset-distance: 100%;
    width: var(--sw);
    height: var(--sh);
    z-index: 0;
    transform: translateY(0) scale(1);
  }
}
