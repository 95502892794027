body {
  margin: 0;
  padding: 0;
  font-family: "Outfit", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* For Desktop and Large Laptops (15.6" and larger) */
@media only screen and (max-width: 1550px) {
  html {
    font-size: 80%; /* Adjust font size for large desktops */
  }
}

/* For Small Laptops (13"-14") */
@media only screen and (max-width: 1440px) {
  html {
    font-size: 75%; /* Slightly smaller font size for small laptops */
  }
}

/* For Tablets */
@media only screen and (max-width: 1024px) {
  html {
    font-size: 70%; /* Smaller font size for tablets */
  }
}

/* For Mobile Phones */
@media only screen and (max-width: 768px) {
  html {
    font-size: 65%;
  }
}

